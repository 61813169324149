import loadable from "@loadable/component";
import { navigate } from "@reach/router";
import Link from "gatsby-link";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import Helmet from "react-helmet";
import { Message } from "../lib/getMessages";

import Ambros from "../images/Ambros.webp";
import Blossom from "../images/Blossom.webp";
import Android from "../images/google-play-badge.png";
import IosLogo from "../images/ios-app.png";
import MedinMinutes from "../images/Medinminutes.webp";
import MobileMockup from "../images/Mobile-mockup.png";
import SelectIndustryIcon from "../images/select-industry.svg";
import SelectThemeIcon from "../images/select-theme.svg";
import StoreInfoIcon from "../images/store-info.svg";
import YourDomainIcon from "../images/your-domain.svg";

import WithProviders from "../components/WithProviders";

import "../commonStyle/style.css";
import "../commonStyle/widget.css";

const Hero = loadable(() => import("../components/Hero"));
const Testimonials = loadable(() => import("../components/Testimonials"));
const Layout = loadable(() => import("../components/Layout"));
const Popup = loadable(() => import("../components/Popup"));
const EazyHeader = loadable(() => import("../components/Header"));
const SignupForm = loadable(() => import("../components/SignupForm"));

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.testimonialsData = [
      {
        message: "testimonials.ambros",
        name: "testimonials.name.ambros",
        designation: "testimonials.designation.ambros",
        company: "testimonials.company.ambros",
        link: "https://ambros.co.in/",
        image: Ambros,
      },
      {
        message: "testimonials.medinmin",
        name: "testimonials.name.medinmin",
        designation: "testimonials.designation.medinmin",
        company: "testimonials.company.medinmin",
        link: "https://medinminutes.com/",
        image: MedinMinutes,
      },
      {
        message: "testimonials.blossom",
        name: "testimonials.name.blossom",
        designation: "testimonials.designation.blossom",
        company: "testimonials.company.blossom",
        link: "https://blossombookhouse.in/",
        image: Blossom,
      },
    ];
    this.state = {
      isLoggedinFromHero: false,
      isLoggedIn: false,
      showSignupModal: false,
    };
  }
  componentDidMount() {
    if (window.localStorage.getItem("token")) {
      this.setState({ isLoggedin: true });
    }
  }
  handleLoggedInfromHero = (isLoggedin) => {
    this.setState({ isLoggedinFromHero: isLoggedin });
  };
  handleClickShowSignupForm = () => {
    this.setState((prevState) => ({
      showSignupModal: !prevState.showSignupModal,
    }));
  };
  setIsLoggedIn = (isLoggedin) => {
    this.setState({ isLoggedin });
  };
  handleSelectStoreForFreeClick = () => {
    if (!this.state.isLoggedIn) {
      this.handleClickShowSignupForm();
      if (window.gtag) {
        window.gtag("event", "button_click", {
          category: "Button",
          label: "Setup your free online store",
        });
      }
    } else {
      window.open("/admin/home/dashboard", "_blank");
    }
  };
  render() {
    const { language, updateLanguage, direction } = this.props;
    const lang = language ? `/${language}` : "";
    let canonicalUrl = `https://staging.zopping.com/`;
    if (typeof window !== "undefined") {
      if (window.location.host === "zopping.com") {
        canonicalUrl = `https://zopping.com/`;
      }
    }
    const isDirectionRTL = direction === "rtl";
    return (
      <Layout
        language={language}
        isDirectionRTL={isDirectionRTL}
        location={this.props.location}
      >
        <div className="eazy-page">
          <Helmet title="Zopping: The ultimate e-commerce platform for retailers">
            <meta
              name="description"
              content=" Create your online store in 3 simple steps and grow your business online. Upload catalogue, collect payments, manage deliveries and much more. No coding required."
            />
            <meta property="og:url" content="https://zopping.com/" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Zopping: The ultimate e-commerce platform for retailers"
            />
            <meta
              property="og:image"
              content="http://storage.googleapis.com/zopping-staging-uploads/originals/20211112/Option21-20211112-035852.png"
            />
            <meta
              property="og:description"
              content="Make an E-Commerce website for free. Zopping provides easy solutions for the web, android and iOS for retailers to sale their products online for free."
            />
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
          <EazyHeader
            language={language}
            updateLanguage={updateLanguage}
            isDirectionRTL={isDirectionRTL}
            current="home"
            isLoggedinFromHero={this.state.isLoggedinFromHero}
          />
          <Hero
            handleLoggedInfromHero={this.handleLoggedInfromHero}
            isDirectionRTL={isDirectionRTL}
          />
          <div className="eazy">
            <section className="intro tc">
              <h1>
                <Message dictKey="start.online.store" />
              </h1>
              <p>
                <Message dictKey="sell.groceries.medicines" />
              </p>
              <hr className="desktop-border" />
              <hr className="mobile-border-top" />
              <div
                className={`steps-icon-wrp ${isDirectionRTL && "rtl-support"}`}
              >
                <div className="icon-desc-wrp">
                  <div className="steps-icon store">
                    <img src={StoreInfoIcon} alt="store-information" />
                  </div>
                  <div className="desc-wrp">
                    <h3>
                      <Message dictKey="store.information" />
                    </h3>
                    <p className="steps-desc">
                      <Message dictKey="enter.store.name.logo" />
                    </p>
                  </div>
                </div>
                <span className="steps-dot yellow-dot"></span>
                <span className="divider"></span>
                <span className="steps-dot blue-dot"></span>
                <div className="icon-desc-wrp">
                  <div className="steps-icon domain">
                    <img src={YourDomainIcon} alt="store-information" />
                  </div>
                  <div className="desc-wrp">
                    <h3>
                      <Message dictKey="select.domain" />
                    </h3>
                    <p className="steps-desc">
                      <Message dictKey="choose.store.url" />
                    </p>
                  </div>
                </div>
                <span className="steps-dot blue-dot"></span>
                <span className="divider"></span>
                <span className="steps-dot orange-dot"></span>
                <div className="icon-desc-wrp">
                  <div className="steps-icon industry">
                    <img src={SelectIndustryIcon} alt="store-information" />
                  </div>
                  <div className="desc-wrp">
                    <h3>
                      <Message dictKey="select.industry" />
                    </h3>
                    <p className="steps-desc">
                      <Message dictKey="pick.business.operate" />
                    </p>
                  </div>
                </div>
                <span className="steps-dot orange-dot"></span>
                <span className="divider"></span>
                <span className="steps-dot green-dot"></span>
                <div className="icon-desc-wrp">
                  <div className="steps-icon theme">
                    <img src={SelectThemeIcon} alt="store-information" />
                  </div>
                  <div className="desc-wrp">
                    <h3>
                      <Message dictKey="select.theme" />
                    </h3>
                    <p className="steps-desc">
                      <Message dictKey="choose.theme.liking" />
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="features wrap">
              <div className="sell-anything-online">
                <section className="feature unique-online-store">
                  <div className="feature-media">
                    <img
                      src={
                        "https://storage.googleapis.com/zopping-uploads/3585/images/originals/20231228/Sellonline-20231228-071610.webp"
                      }
                      srcSet={
                        "https://storage.googleapis.com/zopping-uploads/3585/images/640/20231228/Sellonline-20231228-071610.webp 2x, https://storage.googleapis.com/zopping-uploads/3585/images/1024/20231228/Sellonline-20231228-071610.webp 3x"
                      }
                      alt="unique online store"
                      width="491"
                      height="282"
                    />
                    <div className="demo-buttons-mobile">
                      <button onClick={this.handleSelectStoreForFreeClick}>
                        <Message dictKey="setup.free.online.store" />
                      </button>
                      <a href={`${lang}/contact`}>
                        <Message dictKey="schedule.demo" />
                      </a>
                    </div>
                  </div>
                  <div className="feature-details">
                    <h2>
                      <Message dictKey="anyone.can.sell.anything" />
                    </h2>
                    <p>
                      <Message dictKey="brands.retailers.resellers" />
                      <span>
                        <Message dictKey="span.online.store" />
                      </span>
                      <Message dictKey="and.a.text" />
                      <span>
                        <Message dictKey="span.mobile.app" />
                      </span>
                      <Message dictKey="matter.few.minutes" />
                    </p>
                    <hr className="mobile-border" />
                    <div
                      className={`demo-buttons ${
                        isDirectionRTL && "rtl-support-button"
                      }`}
                    >
                      <button
                        onClick={this.handleSelectStoreForFreeClick}
                        className="demo-button"
                      >
                        <Message dictKey="setup.free.online.store" />
                      </button>
                      <Popup
                        show={this.state.showSignupModal}
                        close={this.handleClickShowSignupForm}
                      >
                        <SignupForm
                          isDirectionRTL={isDirectionRTL}
                          close={this.handleClickShowSignupForm}
                          showLogin={this.handleClickShowSignupForm}
                          setIsLoggedIn={this.setIsLoggedIn}
                        />
                      </Popup>
                      <a href={`${lang}/contact`}>
                        <Message dictKey="schedule.demo" />
                      </a>
                    </div>
                  </div>
                </section>
              </div>

              <div className="sell-anything-online">
                <section className="feature quality-website rev">
                  <div className="feature-media">
                    <img
                      src={
                        "https://storage.googleapis.com/zopping-uploads/3585/images/originals/20231228/WebdevSkillsIcon-20231228-110535.webp"
                      }
                      alt="international quality website"
                    />
                    <Link to={`${lang}/features`}>
                      <button className="explore-features-mobile">
                        <Message dictKey="explore.our.features" />
                      </button>
                    </Link>
                  </div>
                  <div className="feature-details">
                    <h2>
                      <Message dictKey="zero.web.dev.skills" />
                    </h2>
                    <p>
                      <span className="list-text-span">
                        <Message dictKey="span.list.text" />
                      </span>
                      <Message dictKey="products.text" />
                      <span>
                        <Message dictKey="span.manage.text" />
                      </span>
                      <Message dictKey="orders.text" />
                      <span>
                        <Message dictKey="span.receive.text" />
                      </span>
                      <Message dictKey="payments.handle.deliveries" />
                    </p>
                    <hr className="mobile-border" />
                    <a className="explore-features" href={`${lang}/features`}>
                      <Message dictKey="explore.our.features" />
                    </a>
                  </div>
                </section>
              </div>

              <div className="specific-business">
                <section className="feature go-mobile">
                  <div className="feature-media">
                    <img
                      src={
                        "https://storage.googleapis.com/zopping-uploads/3585/images/originals/20231228/Storecustomers-20231228-104816.webp"
                      }
                      alt="go mobile instantly"
                    />
                    <Link to={`${lang}/features`}>
                      <button className="explore-features-mobile">
                        <Message dictKey="explore.our.features" />
                      </button>
                    </Link>
                  </div>
                  <div className="feature-details">
                    <h2>
                      <Message dictKey="your.store.customers.rules" />
                    </h2>
                    <p>
                      <Message dictKey="experience.online.marketplaces" />
                    </p>
                    <hr className="mobile-border" />
                    <a className="explore-features" href={`${lang}/features`}>
                      <Message dictKey="explore.our.features" />
                    </a>
                  </div>
                </section>
              </div>

              <div className="specific-business">
                <section className="feature manage rev">
                  <div className="feature-media">
                    <img
                      src={
                        "https://storage.googleapis.com/zopping-uploads/3585/images/originals/20231228/Specificbusiness-20231228-103539.webp"
                      }
                      alt="manage e-commerce website on the go"
                    />
                    <div className="demo-buttons-mobile">
                      <a href={`${lang}/whyus`}>
                        <Message dictKey="explore.prices" />
                      </a>
                      <AnchorLink
                        to={`${lang}/whyus#pricing-comparison-container`}
                      >
                        <Message dictKey="compare.with.others" />
                      </AnchorLink>
                    </div>
                  </div>
                  <div className="feature-details">
                    <h2>
                      <Message dictKey="platform.specific.business" />
                    </h2>
                    <p>
                      <Message dictKey="our.host.extensions" />
                      <span>
                        <Message dictKey="span.customize.text" />
                      </span>
                      <Message dictKey="your.online.store" />
                      <span>
                        <Message dictKey="span.enterprise.technology.text" />
                      </span>
                      <Message dictKey="handle.thousands.transactions" />
                    </p>
                    <hr className="mobile-border" />
                    <div
                      className={`demo-buttons ${
                        isDirectionRTL && "rtl-support-button"
                      }`}
                    >
                      <a href={`${lang}/whyus`}>
                        <Message dictKey="explore.prices" />
                      </a>
                      <AnchorLink
                        to={`${lang}/whyus#pricing-comparison-container`}
                      >
                        <Message dictKey="compare.with.others" />
                      </AnchorLink>
                    </div>
                  </div>
                </section>
              </div>
              <div
                className={`preview-wrp ${
                  isDirectionRTL && "rtl-support-preview"
                }`}
              >
                <section className="app-download-wrp">
                  <div className="download-icons-wrp">
                    <h3 className="apps-download">
                      <Message dictKey="download.now" />
                    </h3>
                    <p className="download-apps-desc">
                      <Message dictKey="unlock.ecommerce.power" />
                    </p>
                    <div className="apps-icon-wrp">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.zopping.admin"
                        target="_blank"
                        className="android-wrp"
                        rel="noreferrer"
                      >
                        <img src={Android} alt="play-store-logo" />
                      </a>
                      <a
                        href="https://apps.apple.com/in/app/zopping/id1586937529"
                        target="_blank"
                        className={`ios-wrp ios-img ${
                          isDirectionRTL && "rtl-support"
                        }`}
                        rel="noreferrer"
                      >
                        <img src={IosLogo} alt="play-store-logo" />
                      </a>
                    </div>
                  </div>
                  <div
                    className={`mobile-mockup-wrp ${
                      isDirectionRTL && "rtl-mobile-wrp"
                    }`}
                  >
                    <img
                      className="mobile-mockup-img"
                      src={MobileMockup}
                      alt="mobile-mockup"
                    />
                    <img
                      className="splash-screen-img"
                      src={
                        "https://storage.googleapis.com/zopping-uploads/3585/images/originals/20231228/androidanimationframe-20231228-110206.webp"
                      }
                      srcSet={
                        "https://storage.googleapis.com/zopping-uploads/3585/images/640/20231228/androidanimationframe-20231228-110206.webp 2x, https://storage.googleapis.com/zopping-uploads/3585/images/1024/20231228/androidanimationframe-20231228-110206.webp 3x"
                      }
                      alt="splash-screen"
                    />
                  </div>
                </section>
              </div>
            </section>
          </div>
          <Testimonials
            items={this.testimonialsData}
            isDirectionRTL={isDirectionRTL}
          />
        </div>
      </Layout>
    );
  }
}

export default WithProviders(Home);
